import React from 'react';
import MQTTManager from './MQTTManager';

function App() {
  return (
    <div className="App">
      <MQTTManager />
    </div>
  );
}

export default App;